<template>
  <CardComponent :title="title" :icon="icon" :date="date" :tip="tip" :link="link">
    <section>
      <el-tabs v-if="contentData.length > 0" tab-position="left">
        <el-tab-pane v-for="(item,itemIndex) in contentData" :key="itemIndex" :label="item.title">
          <ul class="content">


<div class="table-class">
              <el-table  class="customer-no-border-table"  :data="item.datas" >
              
                <el-table-column
                  prop="jobName"
                  label="岗位"
                  class="label-content"
                ></el-table-column>

                    <el-table-column
                  prop="expectedCount"
                  label="所需人数"
                ></el-table-column>
                     <el-table-column
                  :formatter="formatDate"
                  label="预期到岗时间"
                ></el-table-column>
              </el-table>
  </div>

            <!-- <li v-for="(data,index) in item.datas" :key="index">
              <el-link :underline="false" target="_blank">{{ data.jobName }}</el-link>
              <el-link :underline="false" target="_blank">
  

              </el-link> -->
            <!-- </li> -->
          </ul>
        </el-tab-pane>
      </el-tabs>
      <Empty v-else />
    </section>
  </CardComponent>
</template>

<script>

import CardComponent from "../components/card"
import Empty from "@/components/empty"
export default {
  name: '',
  components: { CardComponent, Empty },
  props: {
    title: {
      type: String,
      default: '本周新增分销商',
      required: true
    },
    tip: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'iconfont icon-daizhaogangwei',
    },
    contentData: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      lastMonday: '',
      lastSunday: '',
      date:''
    }
  },
  created () {
    // 
    // const weekOfday = this.$moment().format('E');
    // if (weekOfday === 1) {
    //   // 取上周日期
    //   this.lastMonday = this.$moment().subtract(30, 'days').format('YYYY.MM.DD');
    //   this.lastSunday = this.$moment().subtract(weekOfday, 'days').format('YYYY.MM.DD');
    // } else {
    //   // 取本周日期
    //   this.lastMonday = this.$moment().subtract(weekOfday - 1, 'days').format('YYYY.MM.DD');
    //   this.lastSunday = this.$moment().add(7 - weekOfday, 'days').format('YYYY.MM.DD');
    // }

    // this.date = this.lastMonday + '-' + this.lastSunday

    let today = this.$moment().format('YYYY.MM.DD');
     let endDay = this.$moment().add(30, 'days').format('YYYY.MM.DD');
    this.date = today + '-' + endDay

    
  },
  methods: {
    formatDate(row, column) {
          // 获取单元格数据
          let data = row.joinDate
          if(data == null) {
              return null
          }
          let dt = new Date(data)
          return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate() 
        },
        
  },
}
</script>
<style lang='less' scoped>
section {
  // padding: 0 30px 30px 30px;
  margin-top: 0.16rem;
  flex: 1;
  box-sizing: border-box;
  overflow: hidden;
  // background-color: yellow;
  /deep/ .el-tabs {
    box-sizing: border-box;
    display: flex;
    height: 100%;
    .el-tabs__content {
      flex: 1;
      padding-left: 0.1rem;
      overflow: hidden;
      .content {
        padding-right: 0.3rem;
        li {
          line-height: 0.48rem;
          height: 0.48rem;
          font-size: 0.16rem;
          color: #636a6f;
          font-weight: 400;
          margin-bottom: 0;
          .el-link {
            display: block;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // white-space: nowrap;
            .el-link--inner {
              display: block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              // letter-spacing: 0.1em;
            }
          }
        }
      }
    }
    .el-tabs__content:hover {
      overflow: auto;
    }
    // .el-tabs__nav:hover {
    //   overflow: auto;
    // }
    .el-tabs__nav-prev,
    .el-tabs__nav-next {
      display: none;
    }
    .el-tabs__nav {
      top: 50%;
      transform: translateY(-50%) !important;
      overflow: auto;
      height: 100%;
      &::-webkit-scrollbar {
        display: none;
      }
      .el-tabs__item.is-left {
        // padding: ;
        // box-sizing: content-box;
        // padding: .3rem 20px;
        height: 0.78rem;
        line-height: 0.78rem;
        font-size: 0.18rem;
      }
      .el-tabs__active-bar.is-left {
        // transform: translateY(.3rem)!important;
        // top: .3rem;
        height: 0.4rem !important;
        width: 0.03rem;
      }
      .el-tabs__item {
        text-align: center;
        min-width: 1.2rem;
        padding: 0 0.2rem;
        color: #606266;
      }
      .el-tabs__item.is-active {
        color: #409eff;
      }
    }
    .el-tabs__nav.is-left {
      .el-tabs__item{
        max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  //定义滑块 内阴影+圆角
  ::-webkit-scrollbar-thumb {
    border-radius: 1em;
    background-color: rgba(50, 50, 50, 0.2);
  }
  //定义滚动条轨道 内阴影+圆角
  ::-webkit-scrollbar-track {
    border-radius: 1em;
    background-color: rgba(50, 50, 50, 0.1);
  }
  /deep/ .el-tabs--left .el-tabs__active-bar.is-left {
    top: 0.19rem;
    // transform: translateY(50%);
  }

}


/deep/ .el-table {
            color: #606266;
            font-size: 0.14rem;
            thead {
                .cell {
                    color: #a7b6c3;
                    font-weight: bold;
                    // font-weight: 500;
                    font-size: 0.14rem;
                }
            }
            tr,
            th,
            td {
                // background-color: #f2f5f9;
                border: none;
            }
            .el-table__body {
                td {
                    color: #606266;
                }
            }
            .el-table__empty-block {
                // background-color: #f2f5f9;
            }
        }
        /deep/ .el-table::before {
            height: 0 !important;
        }
        .label-content{
          font-weight: bold;
        }
</style>